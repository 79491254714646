import { observable } from "mobx";

import whiteLabel, { defaultWhiteLabel } from "domain/services/white-label";

// Default
import authPageLeftTop from "assets/images/themes/default/auth-page-left-top.png";
import authPageLeftBottom from "assets/images/themes/default/auth-page-left-bottom.png";
import authPageRightTop from "assets/images/themes/default/auth-page-right-top.png";
import authPageRightBottom from "assets/images/themes/default/auth-page-right-bottom.png";
import logoIcon from "assets/images/logo-small.svg";
import logoDark from "assets/images/themes/default/logo-dark.svg";
import logoLight from "assets/images/themes/default/logo-light.svg";
import logoBlack from "assets/images/themes/default/logo-black.svg";
import logoWhite from "assets/images/themes/default/logo-white.svg";
import qrcCenterImage from "assets/images/tapwow-logo-small.png";
import message from "assets/images/themes/default/message.svg";
import lock from "assets/images/themes/default/lock.svg";
import messageError from "assets/images/themes/default/message-error.svg";
import lockError from "assets/images/themes/default/lock-error.svg";
import attention from "assets/images/themes/default/attention.svg";
import headerTop from "assets/images/themes/default/header-top.svg";
import footerRight from "assets/images/themes/default/footer-right.svg";
import dirx2DarkLogo from "assets/images/themes/default/logo-small.svg";
import rocket from "assets/images/themes/default/rocket.svg";
import dirx2Logo from "assets/images/logo-big-dark.svg";
import companies from "assets/images/iccompanies.svg";

// Identiv
import logoIdentivLight from "assets/images/themes/identiv/bitse-logo-light.png";
import logoIdentivDark from "assets/images/themes/identiv/bitse-logo-dark.png";

const themeConfig = {
  [defaultWhiteLabel]: {
    accentColor: "#36D453",
    mainDarkColor: "#262626cc",
    mainLightColor: "#F6F5F3",
    mainLighterColor: "#FAFAFA",
    secondaryDarkColor: "#555555",
    secondaryDarkerColor: "#888888",
    tertiaryDarkColor: "#999999",
    secondaryLightColor: "#D6D6D6",
    tertiaryLightColor: "#E7E7E7",
    errorColor: "#FF5858",
    versionColor: "#888888",
    poweredByColor: "#777777",
    logoIcon: logoIcon,
    darkLogo: logoDark,
    lightLogo: logoLight,
    blackLogo: logoBlack,
    whiteLogo: logoWhite,
    whiteLabelLogoLight: logoIcon,
    whiteLabelLogoDark: dirx2Logo,
    aboutModalContent: [],
    borderRadius: "7px",
    icons: {
      companies,
      message,
      lock,
      messageError,
      lockError,
      attention,
      qrcCenterImage
    },
    authPage: {
      bgColor: "#FAFAF8",
      bgImages: {
        authPageLeftTop,
        authPageLeftBottom,
        authPageRightTop,
        authPageRightBottom
      }
    },
    header: {
      bgColor: "#262626",
      bgImage: headerTop
    },
    footer: {
      bgImage: footerRight
    },
    constraintModal: {
      logo: dirx2DarkLogo,
      rocket
    }
  },
  identiv: {
    logoIcon: logoIdentivLight,
    whiteLabelLogoDark: logoIdentivDark,
    whiteLabelLogoLight: logoIdentivLight,
    aboutModalContent: [
      {
        url: "mailto:support@bitseio.com",
        text: "support@bitseio.com"
      }
    ]
  }
};

class Theme {
  @observable theme = {
    accentColor:
      themeConfig[whiteLabel].accentColor ||
      themeConfig[defaultWhiteLabel].accentColor,
    mainDarkColor:
      themeConfig[whiteLabel].mainDarkColor ||
      themeConfig[defaultWhiteLabel].mainDarkColor,
    mainLightColor:
      themeConfig[whiteLabel].mainLightColor ||
      themeConfig[defaultWhiteLabel].mainLightColor,
    mainLighterColor:
      themeConfig[whiteLabel].mainLighterColor ||
      themeConfig[defaultWhiteLabel].mainLighterColor,
    secondaryDarkColor:
      themeConfig[whiteLabel].secondaryDarkColor ||
      themeConfig[defaultWhiteLabel].secondaryDarkColor,
    secondaryDarkerColor:
      themeConfig[whiteLabel].secondaryDarkerColor ||
      themeConfig[defaultWhiteLabel].secondaryDarkerColor,
    tertiaryDarkColor:
      themeConfig[whiteLabel].tertiaryDarkColor ||
      themeConfig[defaultWhiteLabel].tertiaryDarkColor,
    secondaryLightColor:
      themeConfig[whiteLabel].secondaryLightColor ||
      themeConfig[defaultWhiteLabel].secondaryLightColor,
    tertiaryLightColor:
      themeConfig[whiteLabel].tertiaryLightColor ||
      themeConfig[defaultWhiteLabel].tertiaryLightColor,
    errorColor:
      themeConfig[whiteLabel].errorColor ||
      themeConfig[defaultWhiteLabel].errorColor,
    versionColor:
      themeConfig[whiteLabel].versionColor ||
      themeConfig[defaultWhiteLabel].versionColor,
    poweredByColor:
      themeConfig[whiteLabel].poweredByColor ||
      themeConfig[defaultWhiteLabel].poweredByColor,
    logoIcon:
      themeConfig[whiteLabel].logoIcon ||
      themeConfig[defaultWhiteLabel].logoIcon,
    darkLogo:
      themeConfig[whiteLabel].darkLogo ||
      themeConfig[defaultWhiteLabel].darkLogo,
    lightLogo: themeConfig[defaultWhiteLabel].lightLogo,
    blackLogo: themeConfig[defaultWhiteLabel].blackLogo,
    whiteLogo: themeConfig[defaultWhiteLabel].whiteLogo,
    whiteLabelLogoDark:
      themeConfig[whiteLabel].whiteLabelLogoDark ||
      themeConfig[defaultWhiteLabel].whiteLabelLogoDark,
    whiteLabelLogoLight:
      themeConfig[whiteLabel].whiteLabelLogoLight ||
      themeConfig[defaultWhiteLabel].whiteLabelLogoLight,
    aboutModalContent:
      themeConfig[whiteLabel].aboutModalContent ||
      themeConfig[defaultWhiteLabel].aboutModalContent,
    borderRadius:
      themeConfig[whiteLabel].borderRadius ||
      themeConfig[defaultWhiteLabel].borderRadius,
    icons: {
      message:
        themeConfig[whiteLabel].icons?.message ||
        themeConfig[defaultWhiteLabel].icons.message,
      lock:
        themeConfig[whiteLabel].icons?.lock ||
        themeConfig[defaultWhiteLabel].icons.lock,
      messageError:
        themeConfig[whiteLabel].icons?.messageError ||
        themeConfig[defaultWhiteLabel].icons.messageError,
      lockError:
        themeConfig[whiteLabel].icons?.lockError ||
        themeConfig[defaultWhiteLabel].icons.lockError,
      attention:
        themeConfig[whiteLabel].icons?.attention ||
        themeConfig[defaultWhiteLabel].icons.attention,
      companies:
        themeConfig[whiteLabel].icons?.companies ||
        themeConfig[defaultWhiteLabel].icons.companies,
      qrcCenterImage:
        themeConfig[whiteLabel].icons?.qrcCenterImage ||
        themeConfig[defaultWhiteLabel].icons.qrcCenterImage
    },
    authPage: {
      bgColor:
        themeConfig[whiteLabel].authPage?.bgColor ||
        themeConfig[defaultWhiteLabel].authPage.bgColor,
      bgImages: {
        authPageLeftTop:
          themeConfig[whiteLabel].authPage?.bgImages?.authPageLeftTop ||
          themeConfig[defaultWhiteLabel].authPage.bgImages.authPageLeftTop,
        authPageLeftBottom:
          themeConfig[whiteLabel].authPage?.bgImages?.authPageLeftBottom ||
          themeConfig[defaultWhiteLabel].authPage.bgImages.authPageLeftBottom,
        authPageRightTop:
          themeConfig[whiteLabel].authPage?.bgImages?.authPageRightTop ||
          themeConfig[defaultWhiteLabel].authPage.bgImages.authPageRightTop,
        authPageRightBottom:
          themeConfig[whiteLabel].authPage?.bgImages?.authPageRightBottom ||
          themeConfig[defaultWhiteLabel].authPage.bgImages.authPageRightBottom
      }
    },
    header: {
      bgColor:
        themeConfig[whiteLabel].header?.bgColor ||
        themeConfig[defaultWhiteLabel].header.bgColor,
      bgImage:
        themeConfig[whiteLabel].header?.bgImage ||
        themeConfig[defaultWhiteLabel].header.bgImage
    },
    footer: {
      bgImage:
        themeConfig[whiteLabel].footer?.bgImage ||
        themeConfig[defaultWhiteLabel].footer.bgImage
    },
    constraintModal: {
      logo:
        themeConfig[whiteLabel].constraintModal?.logo ||
        themeConfig[defaultWhiteLabel].constraintModal.logo,
      rocket:
        themeConfig[whiteLabel].constraintModal?.rocket ||
        themeConfig[defaultWhiteLabel].constraintModal.rocket
    }
  };

  constructor(rootStore) {
    this.rootStore = rootStore;
  }
}

export default Theme;
