export const authForm = {
  emailPlaceholder: "youremailexample@mail.com",
  emailErrorMessage: "invalid email",
  passwordPlaceholder: "password",
  passwordErrorMessage: "incorrect password",
  companyCodePlaceholder: "00000000",
  companyCodeErrorMessage: "invalid company code",
  newPasswordPlaceholder: "new password",
  confirmPasswordPlaceholder: "confirm new password",
  newPasswordErrorMessage: "must be valid new password",
  repeatPasswordErrorMessage: "password doesn't match",
};
