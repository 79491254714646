import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { isFeatureEnabled } from "utils/helpers";
import { Header } from "components/Content";
import Accordion from "components/Accordion";
import { Button } from "components/actions";
import Div from "components/Div";
import withCancelModal from "components/withCancelModal";
import EditCompanyForm from "features/companies/forms/EditCompany";
import CompanyPrimaryContactForm from "features/companies/forms/CompanyPrimaryContact";
import CompanyBranding from "features/companies/forms/CompanyBranding";
import Users from "./Users/List";
import { accordionTitles } from "./settings";

const Edit = ({
  companiesStore,
  rootStore,
  usersStore,
  constraintsStore,
  match,
  onLeavePage
}) => {
  const { findAll, findById, save, reset, company } = companiesStore;
  const { reset: resetUsers, user } = usersStore;
  const { id } = match.params;
  const isEditRoute = !!id;
  const innerTitle = isEditRoute ? `Edit Company (${company.code})` : "Create Company";

  useEffect(() => {
    const onMount = async () => {
      await constraintsStore.findAll();

      if (id) {
        await findAll();
        await findById({ id })();
        await rootStore.usersStore.findAll();
      }

      if (!id) {
        resetUsers();
      }
    };
    onMount();

    return () => {
      reset();
    };
  }, [
    findAll,
    findById,
    rootStore.usersStore,
    reset,
    id,
    constraintsStore,
    resetUsers
  ]);

  return (
    <>
      <Header
        title={innerTitle}
        backButton
        backButtonFn={onLeavePage(company.isDirty || user.isDirty)}
        backButtonLink="/companies"
        actionButtons={[
          <Div justify="flex-end" key="action-buttons">
            <Button
              type="regularFlat"
              onClick={onLeavePage(company.isDirty || user.isDirty)}
            >
              Cancel
            </Button>
            <Button type="regular" onClick={save(isEditRoute)}>
              Save
            </Button>
          </Div>
        ]}
      />
      <Div
        direction="row"
        justify="space-between"
        paddingTop="36px"
        paddingBottom="100px"
      >
        <Accordion
          titles={accordionTitles}
          defaultActiveKey={[0]}
          separatorWidth="0px"
        >
          <EditCompanyForm feature="details" />
          <CompanyPrimaryContactForm feature="contact" />
          {isFeatureEnabled('whiteLabeling') && <CompanyBranding feature="branding"/>}
          <Div direction="column" bottom={25} width="60%" feature="administrator" >
            <Users match={match} />
          </Div>
        </Accordion>
      </Div>
    </>
  );
};

export default compose(
  withCancelModal("/companies"),
  inject(({ rootStore }) => ({
    companiesStore: rootStore.companiesStore,
    usersStore: rootStore.usersStore,
    constraintsStore: rootStore.constraintsStore,
    rootStore
  })),
  observer
)(Edit);
